export default (table, n) => {
  let switching = true
  let shouldSwitch
  let dir = 1
  let switchcount = 0

  while (switching) {
    switching = false
    const rows = table.querySelectorAll('tbody > tr')
    for (var i = 0; i < (rows.length - 1); i++) {
      shouldSwitch = false
      const aEl = rows[i].getElementsByTagName('td')[n].querySelector('a')
      const bEl = rows[i + 1].getElementsByTagName('td')[n].querySelector('a')

      const a = (parseInt(aEl.getAttribute('data-sortable-value')) || aEl.innerHTML.toLowerCase())
      const b = (parseInt(bEl.getAttribute('data-sortable-value')) || bEl.innerHTML.toLowerCase())

      if (dir > 0) {
        if (a > b) {
          shouldSwitch = true
          break
        }
      } else {
        if (a < b) {
          shouldSwitch = true
          break
        }
      }
    }
    if (shouldSwitch) {
      rows[i].parentNode.insertBefore(rows[i + 1], rows[i])
      switching = true
      switchcount++
    } else {
      if (switchcount === 0 && dir > 0) {
        dir = -1
        switching = true
      }
    }
  }

  return dir
}
