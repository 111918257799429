import view from 'abstractions/barba-view'
import lazy from 'controllers/lazy'
import { translate } from 'controllers/parallax'

export default view('about', {
  onEnterCompleted: refs => {
    refs.lazy = lazy({ selector: '[data-lozad]' })
    refs.translate = translate({
      selector: '.about-section__header-title',
      factor: 0.1
    })
  }
})
