export default ({
  selector = '.slideshow',
  itemSelector = '.slideshow__items-item',
  progressBarSelector = '.slideshow__progress-bar',
  buttonSelector = 'button.slideshow-toggle',
  activeClass = 'is-active',
  runningClass = 'is-running',
  defaultDelay = 5000
} = {}) => {
  const element = document.querySelector(selector)
  if (!element) return

  const items = element.querySelectorAll(itemSelector)
  const button = element.querySelector(buttonSelector)
  const progressBar = element.querySelector(progressBarSelector)

  let index = 0
  let current = items[index]
  let running = true

  if (items.length <= 1) {
    button.style.display = 'none'
    progressBar.style.display = 'none'
    return update()
  }

  const delay = (element.getAttribute('data-delay') || defaultDelay / 1000) * 1000
  element.style.setProperty('--slideshow-delay', `${delay}ms`)

  progressBar.addEventListener('webkitAnimationEnd', update, false)
  progressBar.addEventListener('animationend', update, false)
  progressBar.addEventListener('oanimationend', update, false)

  update()
  if (button) button.addEventListener('click', toggle)

  return {}

  function update () {
    if (!running) return
    nextSlide()
    resetAnimation()
  }

  function toggle (e) {
    e && e.preventDefault()
    running = !running
    button.classList[running ? 'remove' : 'add']('is-paused')
    progressBar.style.animationPlayState = running ? 'running' : 'paused'
  }

  function nextSlide () {
    if (current) current.classList.remove(activeClass)
    current = items[++index % items.length]
    if (current) current.classList.add(activeClass)
  }

  function resetAnimation () {
    element.classList.remove(runningClass)
    void element.offsetWidth
    element.classList.add(runningClass)
  }
}
