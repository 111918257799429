import Barba from 'barba.js'
import noop from 'utils/noop'

export default (namespace, {
  onEnter = noop,
  onEnterCompleted = noop,
  onLeave = noop,
  onLeaveCompleted = noop
} = {}) => {
  const view = Barba.BaseView.extend({
    namespace,

    // the new Container is ready and attached to the DOM
    onEnter () {
      this.refs = {}
      onEnter(this.refs)
    },

    // the Transition has just finished
    onEnterCompleted () {
      Barba.onNewPage()
      onEnterCompleted(this.refs)
    },

    // a new Transition toward a new page has just started
    onLeave () {
      onLeave(this.refs)
      Object.values(this.refs).forEach(ref => ref && ref.destroy && ref.destroy())
      this.refs = undefined
    },

    // the Container has just been removed from the DOM
    onLeaveCompleted () {
      onLeaveCompleted()
    }
  })

  view.init()
  return view
}
