import Barba from 'barba.js'
import ReducerTransition from 'abstractions/barba-transition-reducer'

export default ({
  wrapperId = 'main',
  containerClass = 'wrapper',
  excludedExtensions = /.jpg|.png|.pdf|.jpeg/i,
  transitionsMap = {},
  onNewPage = function () {}
} = {}) => {
  Barba.Pjax.Dom.wrapperId = wrapperId
  Barba.Pjax.Dom.containerClass = containerClass

  Barba.onNewPage = onNewPage

  Barba.Pjax.originalPreventCheck = Barba.Pjax.preventCheck
  Barba.Pjax.preventCheck = (e, el) => {
    if (!Barba.Pjax.originalPreventCheck(e, el)) return false
    if (excludedExtensions.test(el.href.toLowerCase())) return false
    return true
  }

  Barba.Dispatcher.on('linkClicked', el => { Barba.lastClickEl = el })
  Barba.Pjax.getTransition = () => ReducerTransition(transitionsMap)

  Barba.Pjax.init()
  Barba.Prefetch.init()
}
