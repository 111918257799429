/* global Image */

const preloaded = {}

export default src => {
  if (!src) return
  if (preloaded.hasOwnProperty(src)) return

  preloaded[src] = new Image()
  preloaded[src].src = src
}
