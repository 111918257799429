import 'intersection-observer'

import raf from 'raf'
import bowser from 'bowser'
import domCoords from 'utils/dom-coords-document'

/* global IntersectionObserver */

const translateGPU = (x, y, { decimals = 2 } = {}) =>
  `matrix3d(1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, ${x.toFixed(decimals)}, ${y.toFixed(decimals)}, 0, 1)`

function setVisibilityFlag (entries) {
  entries.forEach(entry => {
    entry.target.isVisible = entry.isIntersecting
  })
}

export const translate = ({
  selector = 'h1',
  factor = 0.1
} = {}) => {
  if (bowser.mobile) return

  let elements = document.querySelectorAll(selector)
  let observer = new IntersectionObserver(setVisibilityFlag)

  init()

  // TODO: throttle init calls
  window.addEventListener('resize', init)

  raf.add(update)

  const api = {
    destroy: () => {
      window.removeEventListener('resize', init)
      raf.remove(update)
      elements = undefined
      observer = undefined
    }
  }

  return api

  function update (dt) {
    const windowScrollY = window.scrollY
    elements.forEach(el => {
      if (!el.isVisible) return
      el.style.transform = translateGPU(0, (el.top - windowScrollY) * factor)
    })
  }

  function init () {
    elements.forEach(el => {
      if (!el || !el.style) return
      el.top = domCoords(el)[1]
      el.style.willChange = 'transform'
      observer.observe(el)
    })
  }
}

export const background = ({
  selector = '.project-cover',
  factor = 0.1,
  // NOTE: this allows manual updates via API, when using inside another raf
  useRaf = true
} = {}) => {
  if (bowser.mobile) return

  let element = document.querySelector(selector)
  if (!element || !element.style) return

  if (bowser.gecko) {
    // NOTE: fallback to background-attachment method for parallax in Firefox,
    // mainly because of how poorly Firefox handles scroll thread
    element.style.backgroundAttachment = 'fixed'
    return
  }

  element.style.willChange = 'transform'

  if (useRaf) raf.add(update)
  else update()

  const api = {
    update,
    destroy: () => {
      if (useRaf) raf.remove(update)
      element = undefined
    }
  }

  return api

  function update (dt) {
    element.style.transform = translateGPU(0, window.scrollY * (1 - factor))
  }
}
