import view from 'abstractions/barba-view'
import slideshow from 'components/slideshow'

export default view('home', {
  onEnterCompleted: refs => {
    refs.slideshow = slideshow({
      selector: '.slideshow',
      itemSelector: '.slideshow__items-item',
      buttonSelector: '.slideshow__toggle'
    })
  }
})
