import anime from 'animejs'
import { background } from 'controllers/parallax'

export default (container = document, { duration = 0 } = {}) => {
  const menu = container.querySelector('.menu')
  const cover = container.querySelector('.project__cover')
  if (!cover) return

  const scrollY = menu.offsetHeight + cover.offsetHeight - (window.innerHeight / 2)

  // NOTE: scroll only if above the fold
  if (scrollY < window.scrollY) return

  if (!duration) {
    window.scrollTo(0, scrollY)
    return true
  }

  const parallax = background({
    selector: '.project__cover-image',
    factor: 0.1,
    useRaf: false
  })

  return anime({
    targets: 'html, body',
    scrollTop: scrollY,
    update: parallax && parallax.update,
    complete: parallax && parallax.destroy,
    duration,
    easing: 'easeInOutExpo'
  }).finished
}
