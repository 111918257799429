import sortTable from 'utils/sort-table'

export default ({
  tableSelector = 'table'
} = {}) => {
  const table = document.querySelector(tableSelector)
  if (!table) return

  const theads = table.querySelectorAll('thead')
  const ths = []
  theads.forEach(thead => {
    thead.querySelectorAll('th').forEach((th, index) => {
      th.index = index
      ths.push(th)
    })
  })

  ths.forEach(th => {
    th.querySelector('a').addEventListener('click', e => {
      e.preventDefault()
      const dir = sortTable(table, th.index)
      displayArrow(dir, th.index)
    })
  })

  function displayArrow (dir, index) {
    table.querySelectorAll('.is-sorting').forEach(el => el.classList.remove('is-sorting', 'is-sorting-ASC', 'is-sorting-DESC'))

    theads.forEach(thead => {
      const th = thead.querySelectorAll('th')[index]
      th.classList.remove('is-sorting-DESC', 'is-sorting-ASC')
      th.classList.add(dir > 0 ? 'is-sorting-ASC' : 'is-sorting-DESC')
      th.classList.add('is-sorting')
    })
  }

  const api = {}
  return api
}
