import view from 'abstractions/barba-view'
import lazy from 'controllers/lazy'
import gallery from 'components/gallery'
import { background } from 'controllers/parallax'
import scrollToProject from 'utils/scroll-to-project'

document.addEventListener('DOMContentLoaded', () => scrollToProject(document, { duration: 500 }), false)

export default view('project', {
  onEnterCompleted: refs => {
    refs.lazy = lazy({ selector: '[data-lozad]' })
    refs.gallery = gallery({ imgSelector: '.gallery img' })
    refs.background = background({
      selector: '.project__cover-image',
      factor: 0.1
    })
  }
})
