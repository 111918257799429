import bowser from 'bowser'
import anime from 'animejs'
import scrollToProject from 'utils/scroll-to-project'

export default (oldContainer, newContainer, lastClickEl, done) => {
  if (bowser.mobile) {
    window.scrollTo(0, 0)
    return done()
  }

  const oldContainerChildren = [...oldContainer.children].filter(el => el.getAttribute('role') !== 'navigation')
  const newContainerChildren = [...newContainer.children].filter(el => el.getAttribute('role') !== 'navigation')

  Promise.resolve()
    .then(() => document.body.classList.add('is-loading'))
    .then(() => anime({
      targets: oldContainerChildren,
      opacity: [1, 0],
      duration: 150,
      easing: 'easeInExpo'
    }).finished)
    .then(() => window.scrollTo(0, 0))
    .then(() => scrollToProject(newContainer))
    .then(done)
    .then(() => anime({
      targets: newContainerChildren,
      opacity: [0, 1],
      duration: 300,
      easing: 'easeOutExpo'
    }).finished)
    .then(() => document.body.classList.remove('is-loading'))
}
