import view from 'abstractions/barba-view'
import lazy from 'controllers/lazy'
import gallery from 'components/gallery'
import { translate } from 'controllers/parallax'

export default view('blog-article', {
  onEnterCompleted: refs => {
    refs.lazy = lazy({ selector: '[data-lozad]' })
    refs.gallery = gallery({ imgSelector: '.gallery img' })
    refs.translate = translate({
      selector: '.blog-article__header-title',
      factor: 0.1
    })
  }
})
