import bowser from 'bowser'
import view from 'abstractions/barba-view'
import lazy from 'controllers/lazy'
import table from 'components/projects-list.table'
import { translate } from 'controllers/parallax'
import preload from 'utils/preload-image'

export default view('projects', {
  onEnterCompleted: refs => {
    refs.lazy = lazy({ selector: '[data-lozad]' })
    refs.translate = translate({
      selector: '.projects-list-default__item-legend',
      factor: 0.1
    })

    refs.table = table({ tableSelector: '.projects-list-table' })

    if (bowser.mobile) return

    const items = document.querySelectorAll('.projects-list-default__item')
    items.forEach(item => item.addEventListener('mouseenter', () => {
      preload(item.getAttribute('data-preload'))
    }))
  }
})
