import barba from 'controllers/barba'
import menu from 'components/menu'

import 'views/about'
import 'views/blog'
import 'views/blog-article'
import 'views/contact'
import 'views/home'
import 'views/project'
import 'views/projects'

import defaultTransition from 'views/transitions/default'
import homeToProject from 'views/transitions/home-to-project'

barba({
  onNewPage: () => menu(),
  transitionsMap: {
    home: { project: homeToProject },
    default: defaultTransition
  }
})
