export default ({
  selector = '.menu',
  activeClass = 'is-active'
} = {}) => {
  const element = document.querySelector(selector)
  if (!element || !element.classList) return

  const activeScrollThreshold = element.offsetHeight / 2

  update()
  window.addEventListener('scroll', update)

  const api = {
    destroy: () => window.removeEventListener('scroll', update)
  }

  return api

  function update () {
    element.classList[(window.scrollY > activeScrollThreshold) ? 'remove' : 'add'](activeClass)
  }
}
